var exports = {};
!function (e, t) {
  exports = t();
}(window, function () {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var i = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(i.exports, i, i.exports, n), i.l = !0, i.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var i in e) n.d(r, i, function (t) {
        return e[t];
      }.bind(null, i));
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "", n(n.s = 0);
  }([function (e, t, n) {
    "use strict";

    n.r(t), n.d(t, "StyleInlineTool", function () {
      return c;
    });

    class r extends HTMLElement {}

    class i extends Error {
      constructor(...e) {
        super(...e), this.name = "EditorJSStyleError";
      }

    }

    var s,
        o,
        l = function (e, t, n, r, i) {
      if ("m" === r) throw new TypeError("Private method is not writable");
      if ("a" === r && !i) throw new TypeError("Private accessor was defined without a setter");
      if ("function" == typeof t ? e !== t || !i : !t.has(e)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
      return "a" === r ? i.call(e, n) : i ? i.value = n : t.set(e, n), n;
    },
        a = function (e, t, n, r) {
      if ("a" === n && !r) throw new TypeError("Private accessor was defined without a getter");
      if ("function" == typeof t ? e !== t || !r : !t.has(e)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
      return "m" === n ? r : "a" === n ? r.call(e) : r ? r.value : t.get(e);
    };

    class c {
      constructor({
        api: e
      }) {
        s.set(this, void 0), o.set(this, void 0), l(this, s, document.createElement("div"), "f"), l(this, o, e, "f");
      }

      static get isInline() {
        return !0;
      }

      static get sanitize() {
        return {
          "editorjs-style": {
            class: !0,
            id: !0,
            style: !0
          }
        };
      }

      static get title() {
        return "Style";
      }

      static prepare() {
        customElements.get("editorjs-style") || customElements.define("editorjs-style", r);
      }

      get shortcut() {
        return "CMD+S";
      }

      checkState() {
        var e;
        a(this, s, "f").innerHTML = "";
        const t = a(this, o, "f").selection.findParentTag("EDITORJS-STYLE");
        if (!t) return !1;
        a(this, s, "f").innerHTML = `\n      <div style="margin-bottom: 16px; margin-left: 16px; margin-right: 16px; ">\n        <div style="display: flex; align-items: center; justify-content: space-between; ">\n          <div>Style settings</div>\n\n          <button class="delete-button ${a(this, o, "f").styles.settingsButton}" type="button">\n            <svg class="icon" height="24" viewBox="0 0 24 24" width="24">\n              <path d="M0 0h24v24H0z" fill="none"/>\n              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>\n            </svg>\n          </button>\n        </div>\n\n        <label style="display: flex; align-items: center; justify-content: space-between; ">\n          <span>ID</span>\n\n          <input class="id-input ${a(this, o, "f").styles.input}" style="width: 80%; ">\n        </label>\n\n        <label style="display: flex; align-items: center; justify-content: space-between; ">\n          <span>Class</span>\n\n          <input class="class-input ${a(this, o, "f").styles.input}" style="width: 80%; ">\n        </label>\n\n        <label style="display: flex; align-items: center; justify-content: space-between; ">\n          <span>Style</span>\n\n          <textarea\n            class="style-textarea ${a(this, o, "f").styles.input}"\n            placeholder="background: #ffe7e8;"\n            style="resize: none; width: 80%; ">\n          </textarea>\n        </label>\n      </div>\n    `;
        const n = a(this, s, "f").querySelector(".delete-button"),
              r = a(this, s, "f").querySelector(".class-input"),
              l = a(this, s, "f").querySelector(".id-input"),
              c = a(this, s, "f").querySelector(".style-textarea");
        if (!(n && r && l && c)) throw new i();
        return n.addEventListener("click", () => {
          const e = Array.from(t.childNodes).map(e => e.cloneNode(!0));
          if (e.forEach(e => {
            var n;
            return null === (n = t.parentNode) || void 0 === n ? void 0 : n.insertBefore(e, t);
          }), t.remove(), 0 === e.length) return;
          const n = window.getSelection();
          if (!n) throw new i();
          n.removeAllRanges();
          const r = new Range();
          r.setStartBefore(e[0]), r.setEndAfter(e[e.length - 1]), n.addRange(r), a(this, s, "f").innerHTML = "", a(this, o, "f").tooltip.hide();
        }), a(this, o, "f").tooltip.onHover(n, "Delete style", {
          placement: "top"
        }), r.value = t.className, r.addEventListener("input", () => t.setAttribute("class", r.value)), l.value = t.id, l.addEventListener("input", () => t.id = l.value), c.value = null !== (e = t.getAttribute("style")) && void 0 !== e ? e : "", c.addEventListener("keydown", e => e.stopPropagation()), c.addEventListener("input", () => t.setAttribute("style", c.value)), !0;
      }

      clear() {
        a(this, s, "f").innerHTML = "";
      }

      render() {
        const e = document.createElement("button");
        return e.classList.add(a(this, o, "f").styles.inlineToolButton), e.type = "button", e.innerHTML = "\n      <svg class=\"icon\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\">\n        <path d=\"M0 0h24v24H0z\" fill=\"none\"/>\n        <path d=\"M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zM7.88 8.75c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2 11c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z\"/>\n      </svg>\n    ", e;
      }

      renderActions() {
        return a(this, s, "f");
      }

      surround(e) {
        const t = new r();
        e.surroundContents(t), a(this, o, "f").selection.expandToTag(t);
      }

    }

    s = new WeakMap(), o = new WeakMap();
  }]);
});
export default exports;
export const EditorJSStyle = exports.EditorJSStyle;